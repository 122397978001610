/// <reference path="../../types/config.d.ts" />

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger)

class HorizontalLine{
  static HorizontalAnchorLineOptions :HorizontalAnchorLineOption = {
    offsetSelector: 'header',
    activeClass: 'actived',
    ease: 'none',
    durationSec: 0.2,
  }
  static HorizontalAnchorLine (
    targetSelector :string,
    lineSelector :string,
    HorizontalAnchorLineoptions :HorizontalAnchorLineOption = HorizontalLine.HorizontalAnchorLineOptions
  ) :void {
    const option = {
      ...HorizontalLine.HorizontalAnchorLineOptions,
      ...HorizontalAnchorLineoptions,
    }
    let leftOffset = Math.min(...Array.from(document.querySelectorAll(targetSelector)).map(elm => elm.getBoundingClientRect().left))
    const getCurrentSection = () => {
      return Array.from(document.querySelectorAll(targetSelector)).find((li, i) => {
        const headerHight = Math.round((document.querySelector(option.offsetSelector) as HTMLElement).getBoundingClientRect().height)
        const id = (li.querySelector('a') as HTMLElement).getAttribute('href') || ''
        const rect = (document.querySelector(id) as HTMLElement).getBoundingClientRect()
        return rect.top < headerHight && headerHight < rect.bottom
      }) || null
    }

    const switching = (resize = false) => {
      leftOffset = Math.min(...Array.from(document.querySelectorAll(targetSelector)).map(elm => elm.getBoundingClientRect().left))
      const sectionMenuElm = getCurrentSection() as HTMLElement
      const currentMenuElm = document.querySelector(`${targetSelector}.${option.activeClass}`) as HTMLElement

      if(resize && currentMenuElm !== null && sectionMenuElm !== null){
        HorizontalLine.moveShift(
          currentMenuElm,
          sectionMenuElm,
          targetSelector,
          lineSelector,
          leftOffset,
          option
        )
      }else if(sectionMenuElm === currentMenuElm){
        return
      }else if(sectionMenuElm === null && currentMenuElm !== null){
        HorizontalLine.fadeOut(
          targetSelector,
          lineSelector,
          leftOffset,
          option
        )
      }else if(sectionMenuElm !== null && currentMenuElm === null){
        HorizontalLine.fadeIn(
          sectionMenuElm,
          lineSelector,
          leftOffset,
          option
        )
      }
    }

    window.addEventListener('resize', () => {
      switching(true)
    })

    Array.from(document.querySelectorAll(targetSelector)).forEach(elm => {
      const headerHight = Math.round((document.querySelector(option.offsetSelector) as HTMLElement).getBoundingClientRect().height)
      const id = (elm.querySelector('a') as HTMLElement).getAttribute('href')

      ScrollTrigger.create({
        trigger: id,
        start: `top-=${headerHight}px top`,
        end: `bottom-=${headerHight}px top`,
        onToggle: self => {
          switching()
        },
      })
    })
  }

  static HorizontalHoverLineOptions :HorizontalHoverLineOption = {
    activeClass: 'actived',
    ease: 'none',
    durationSec: 0.2,
    initPosition: -1,
  }

  static HorizontalHoverLine (
    targetSelector :string,
    lineSelector :string,
    HorizontalHoverLineOptions :HorizontalHoverLineOption = HorizontalLine.HorizontalHoverLineOptions
  ) {
    const option = {
      ...HorizontalLine.HorizontalHoverLineOptions,
      ...HorizontalHoverLineOptions,
    }
    let timer :NodeJS.Timeout | undefined

    let leftOffset = Math.min(...Array.from(document.querySelectorAll(targetSelector)).map(elm => elm.getBoundingClientRect().left))
    const getCurrentSection = (x :number, y :number) => {
      return Array.from(document.querySelectorAll(targetSelector)).find((li, i) => {
        const {marginTop, marginRight, marginBottom, marginLeft} = window.getComputedStyle(li)
        const rect = li.getBoundingClientRect()
        const top = rect.top + scrollY - Number(marginTop.replace('px', ''))
        const bottom = rect.bottom + scrollY + Number(marginBottom.replace('px', ''))
        const left = rect.left + scrollX - Number(marginLeft.replace('px', ''))
        const right = rect.right + scrollX + Number(marginRight.replace('px', ''))
        return top < y && y < bottom && left < x && x < right
      }) || null
    }

    let x :number, y :number
    const switching = () => {  
      if(timer){
        clearTimeout(timer)
      }

      timer = setTimeout(() => {
        const sectionMenuElm = getCurrentSection(x, y) as HTMLElement
        const currentMenuElm = document.querySelector(`${targetSelector}.${option.activeClass}`) as HTMLElement

        if(currentMenuElm !== null && sectionMenuElm !== null){
          HorizontalLine.moveShift(
            currentMenuElm,
            sectionMenuElm,
            targetSelector,
            lineSelector,
            leftOffset,
            option
          )
        }else if(sectionMenuElm === currentMenuElm){
          return
        }else if(sectionMenuElm === null && currentMenuElm !== null){
          if(option.initPosition !== -1){
            const defaultMenuElm = Array.from(document.querySelectorAll(targetSelector)).find((_, i) => {
              return i === option.initPosition
            }) as HTMLElement
            HorizontalLine.moveShift(
              currentMenuElm,
              defaultMenuElm,
              targetSelector,
              lineSelector,
              leftOffset,
              option
            )
          }else{
            HorizontalLine.fadeOut(
              targetSelector,
              lineSelector,
              leftOffset,
              option
            )
          }
        }else if(sectionMenuElm !== null && currentMenuElm === null){
          HorizontalLine.fadeIn(
            sectionMenuElm,
            lineSelector,
            leftOffset,
            option
          )
        }
      }, 100)
    }

    const initLine = () => {
      leftOffset = Math.min(...Array.from(document.querySelectorAll(targetSelector)).map(elm => elm.getBoundingClientRect().left))

      const initLineElm = document.querySelector(lineSelector) as HTMLElement
      if(option.initPosition === -1){
        gsap.set(initLineElm, {
          width: 0,
        })
      }

      Array.from(document.querySelectorAll(targetSelector)).forEach((elm, i) => {
        if(i === option.initPosition){
          const elmRect = elm.getBoundingClientRect()
          gsap.set(initLineElm, {
            left: elmRect.left,
            width: elmRect.width,
          })
          elm.classList.add(option.activeClass)
        }
      })
    }

    document.addEventListener('mousemove', (e) => {
      x = (e as MouseEvent).pageX
      y = (e as MouseEvent).pageY
    })

    window.addEventListener('resize', (e) => {
      initLine()
    })

    window.addEventListener('load', () => {
      initLine()
    })
    initLine()

    Array.from(document.querySelectorAll(targetSelector)).forEach((elm, i) => {
      ScrollTrigger.observe({
        target: elm,
        onHover: () => {
          switching()
        },
        onHoverEnd: () => {
          switching()
        }
      })
    })
  }

  static moveShift (
    srcElm :HTMLElement,
    distElm :HTMLElement,
    targetSelector :string,
    lineSelector :string,
    leftOffset: number,
    option :HorizontalLineOption,
  ) :void {
    Array.from(document.querySelectorAll(`${targetSelector}.${option.activeClass}`)).forEach(el => {
      el.classList.remove(option.activeClass)
    })
    distElm.classList.add(option.activeClass)

    const srcRect = srcElm.getBoundingClientRect()
    const distRect = distElm.getBoundingClientRect()

    if(srcRect.left < distRect.left){
      const tl = gsap.timeline()
      tl.to(lineSelector, {
        width: distRect.right - srcRect.left,
        ease: option.ease,
        duration: option.durationSec,
      }).to(lineSelector, {
        width: distRect.width,
        left: distRect.left - leftOffset,
        ease: option.ease,
        duration: option.durationSec,
      })
    }else{
      const tl = gsap.timeline()
      tl.to(lineSelector, {
        left: distRect.left - leftOffset,
        width: srcRect.right - distRect.left,
        ease: option.ease,
        duration: option.durationSec,
      }).to(lineSelector, {
        width: distRect.width,
        ease: option.ease,
        duration: option.durationSec,
      })
    }
  }

  static fadeIn (
    elm :HTMLElement,
    lineSelector :string,
    leftOffset: number,
    option :HorizontalLineOption,
  ) {
    elm.classList.add(option.activeClass)
    const elmRect = elm.getBoundingClientRect()

    const tl = gsap.timeline()
    tl.fromTo(lineSelector, {
      left: elmRect.left - leftOffset,
      width: 0,
    },{
      left: elmRect.left - leftOffset,
      width: elmRect.width,
      ease: option.ease,
      duration: option.durationSec,
    })
  }

  static fadeOut (
    targetSelector :string,
    lineSelector :string,
    leftOffset: number,
    option :HorizontalLineOption,
    ) {
    Array.from(document.querySelectorAll(`${targetSelector}.${option.activeClass}`)).forEach(el => {
      el.classList.remove(option.activeClass)
    })

    const lineElm = (document.querySelector(lineSelector) as HTMLElement).getBoundingClientRect()
    const tl = gsap.timeline()
    tl.to(lineSelector, {
      left: lineElm.left + lineElm.width - leftOffset,
      width: 0,
      ease: option.ease,
      duration: option.durationSec,
    })
  }


}

export default HorizontalLine
