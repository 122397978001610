/// <reference path="../types/config.d.ts" />

import UWTool from "../lib/uw-tool"
import HorizontalLine from "./lib/HorizontalLine"

class UWS {
  static MultiFilterOption :MultiFilterOption  = {
    triggerActClass: 'actived',
    targetDeactClass: 'hide',
    URLsearch: '',
  }

  static MultiFilter (
    triggerSelector :string,
    targetSelector :string,
    option :MultiFilterOption,
  ) {
    const opt = { ...UWS.MultiFilterOption, ...option }
    const triggers = Array.from(document.querySelectorAll(`${triggerSelector}`)).map(t => (t as HTMLElement).dataset.uwFilterIndex || '').filter(d => d !== '')
    Array.from(document.querySelectorAll(triggerSelector)).forEach(triggerElm => {
      triggerElm.addEventListener('click', () => {
        const triggers = triggerSelector.split(',').map(s => `${s}.${opt.triggerActClass}`)
        const actTriggers = document.querySelectorAll(triggers.join(', '))
        const filters = Array.from(actTriggers).map(t => (t as HTMLElement).dataset.uwFilterIndex || '').filter(d => d !== '')

        Array.from(document.querySelectorAll(targetSelector)).forEach(target => {
          const targetElm = target as HTMLElement
          if(actTriggers.length === 0){
            targetElm.classList.remove(opt.targetDeactClass)
            return
          }
  
          if(targetElm.dataset.uwFilter !== void 0){
            const keys = targetElm.dataset.uwFilter.split(' ')
            if(keys.some(k => filters.includes(k))){
              targetElm.classList.remove(opt.targetDeactClass)
              return
            }
          }
  
          targetElm.classList.add(opt.targetDeactClass)
        })

        //save params
        UWTool.setParamsURL(opt.URLsearch, filters)
      })
    })

    //all clear
    const clears = triggerSelector.split(',').map(s => `${s}[data-uw-filter-clear]`)
    Array.from(document.querySelectorAll(clears.join(', '))).forEach(clearElm => {
      clearElm.addEventListener('click', () => {
        const clearTargets = (clearElm as HTMLElement).dataset.uwFilterClear?.trim()?.split(' ').filter(t => t.length !== 0) || []
        Array.from(document.querySelectorAll(targetSelector)).forEach(elm => {
          if(clearTargets.length === 0){
            elm.classList.remove(opt.targetDeactClass)
          }else{
            const uwFilter = (elm as HTMLElement).dataset.uwFilter?.trim().split(' ').filter(t => t.length !== 0) || []
            if(uwFilter.some(f => clearTargets.includes(f))){
              elm.classList.remove(opt.targetDeactClass)
            }
          }
        })
      })
    })

    //load params
    if(opt.URLsearch.length !== 0){
      const conditions = triggers.map(f => {
        return {
          param: f,
          element: document.querySelector(`[data-uw-filter-index="${f}"]`) as HTMLElement,
        }
      })
      UWTool.setParamsAccess(opt.URLsearch, conditions, (element :HTMLElement) => {
        element.click();
      });
    }
  }

  static HorizontalMenuLine = HorizontalLine.HorizontalAnchorLine
  static HorizontalHoverLine = HorizontalLine.HorizontalHoverLine

  static DomVariableOption :DomVariableOption  = {
    styleId: 'uw-styleVariable',
    fraction: 'raw',
    unit: 'px',
  }

  static DomVariableDocument (
    name :string,
    selector :string,
    property :string,
    option :DomVariableOption
  ) {
    const opt = { ...UWS.DomVariableOption, ...option }
    const callback = () => {
      const elm = document.querySelector(selector) as HTMLElement
      if(elm !== null){
        // @ts-ignore
        const rawValue = elm.getBoundingClientRect()[property]
        let parsedValue :number
        if(opt.unit === 'vw'){
          parsedValue = (rawValue * 100) / window.innerWidth
        }else if(opt.unit === 'vh'){
          parsedValue = (rawValue * 100) / window.innerHeight
        }else{
          parsedValue = rawValue
        }
        let resultProperty :number
        if(opt.fraction === 'round'){
          resultProperty = Math.round(parsedValue)
        }else if(opt.fraction === 'floor'){
          resultProperty = Math.floor(parsedValue)
        }else if(opt.fraction === 'ceil'){
          resultProperty = Math.ceil(parsedValue)
        }else{
          resultProperty = parsedValue
        }

        UWTool.setStyleVariable(opt.styleId, {
          [name]: `${resultProperty}${opt.unit}`
        })
      }
    }

    callback()
    const resizeObs = new ResizeObserver(callback)
    resizeObs.observe(document.querySelector(selector) as HTMLElement)
  }

}

export default UWS;
